<template>
  <div class="organization_del_box">
    <div class="search-box">
      <search-com @search-btn="searchBtn" ref="searchCom"></search-com>
    </div>
    <div class="nav_box">
      <welfare-breadcrumb :itemBreadcrumb="itemBreadcrumb"></welfare-breadcrumb>
    </div>
    <div class="w1200">
      <div class="top-info-box">
        <div class="left-box">
          <!-- 轮播图 -->
          <div class="carousel-box">
            <el-carousel class="info-carousel" indicator-position="outside" arrow="nerver">
              <el-carousel-item>
                  <img :src="detailInfo.hospImage">
              </el-carousel-item>
            </el-carousel>
          </div>
          <!-- 简介 -->
          <div class="introduct-box">
            <h3>{{detailInfo.hospName}}</h3>
            <div class="introduct-item ">
              <span class="gray-text">
                {{detailInfo.hospRank}}
              </span>
            </div>
            <div class="introduct-item">
              <div class="label-text">机构地址：</div>
              <span class="gray-text one-line" ref="adressText"  :class="isShowAllAddressFlag ? 'show-all-adress' : ''" >{{detailInfo.hospAddress}}</span>
              <span class="blue-text" v-if="!isShowAllAddressFlag && isTextEllipsis" @click="showAllAdress">详情</span>
            </div>
            <div class="introduct-item">
              <div class="label-text">体检时间：</div>
              <span class="gray-text">{{detailInfo.hospWorkTime}}</span>
            </div>
            <div class="introduct-item">
              <div class="label-text">预约通知：</div>
              <span class="gray-text">{{detailInfo.hospNotice}}</span>
            </div>
            <div class="introduct-item df">
              <div class="label-text">机构简介：</div>
              <div class="right-text">
                <div class="gray-text bref-text" v-html="detailInfo.hospDescription"></div>
                <span class="blue-text" @click="goTabs">详情>></span>
              </div>
            </div>
          </div>
        </div>
        <!-- 右边 日历 -->
        <div class="right-box">
          <el-calendar v-model="currentDay">
            <template slot="dateCell" slot-scope="{ data }">
              <div class="day-item">
                {{ data.day.split('-').slice(2).join() }} 
                <!-- 渲染 spot 占位符 -->
                <div v-for="(item,index) in selectedDate" :key="index">
                  <span class="spot"
                    v-if="item.date == data.day" 
                    :class="item.isSelectable ? 'green-spot' : 'gray-spot' ">
                  </span>
                </div>
                <!-- 如果没有匹配的日期，则仍然渲染一个空的占位符 -->
                <div v-if="!selectedDate.some(item => item.date === data.day)">
                  <span class="spot"></span>
                </div>
              </div>
            </template>
          </el-calendar>
          <div class="spot-box">
            <div class="spot-item">
              <span class="spot green-spot"></span>
              <span>可预约</span>
            </div>
            <div class="spot-item">
              <span class="spot gray-spot"></span>
              <span>不可预约</span>
            </div>
          </div>
        </div>
      </div>
      <div class="tabs-box">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="套餐列表" name="first"></el-tab-pane>
          <el-tab-pane label="机构简介" name="second"></el-tab-pane>
        </el-tabs>
        <div class="tabs-contant-box">
          <div class="tabs-contant-item" v-if="activeName == 'first'">
            <div class="choose_box">
              <div class="choose_item">
                <span class="choose_text">性别</span>：
                <div class="list_box">
                  <div class="list_item_box" @click="selectOption('Sex',-1)">
                    <span :class="chooseList.Sex==-1?'list_item choose_active':'list_item'">不限</span>
                  </div>
                  <div class="list_item_box" 
                    v-for="taocanItem in sexList" 
                    :key="taocanItem.typeId" 
                    @click="selectOption('Sex', taocanItem.typeId)">
                    <span :class="chooseList.Sex == taocanItem.typeId?'list_item choose_active':'list_item'">      
                      {{taocanItem.typeName}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="choose_item">
                <span class="choose_text">套餐类别</span>：
                <div class="list_box">
                  <div class="list_item_box" @click="selectOption('MealType','')">
                    <span :class="chooseList.MealType==''?'list_item choose_active':'list_item'">不限</span>
                  </div>
                  <div class="list_item_box" 
                    v-for="taocanItem in taocanList" 
                    :key="taocanItem.typeId" 
                    @click="selectOption('MealType', taocanItem.typeId)">
                    <span :class="chooseList.MealType==taocanItem.typeId?'list_item choose_active':'list_item'">      
                      {{taocanItem.typeName}}
                    </span>
                  </div>
                  <div class="list_more" v-if="taocanList.length>9">
                    <span>更多<i :class="taocanShowMore?'el-icon-arrow-up':'el-icon-arrow-down'"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <!-- 表格 -->
            <div class="table-box">
              <el-table
                :data="tableData"
                style="width: 100%"
                :header-cell-style="{ 'background-color': '#EFF8FF', 'color': '#333', 'font-size': '16px' }">
                <el-table-column
                  prop="mealType"
                  label="类型"
                  align="center"
                  >
                </el-table-column>
                <el-table-column
                  prop="mealName"
                  label="体检套餐"
                  >
                  <template slot-scope="scope">
                    <div class="meal-name">{{scope.row.mealName}}</div>
                    <div class="tag-box">
                      <div class="tag-item" v-for="tag in scope.row.mealTypeDic.slice(0, 3)">{{tag.name}}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="price"
                  label="价格"
                  align="center"
                  >
                  <template slot-scope="scope">
                    <div class="price-box">
                      <div class="fz16">￥</div>
                      {{scope.row.price}}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="date"
                  label="操作"
                  align="center"
                >
                  <template slot-scope="scope">
                    <div class="btn-box">
                      <div class="btn-item see-btn" @click="seeRowDetail(scope.row)">
                        查看详情
                      </div>
                      <div class="btn-item add-btn" @click="addCarBtn(scope.row)">
                        加入购物车
                      </div>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="tabs-contant-item second-box" v-else>
            <h3>{{detailInfo.hospName}}</h3>
            <div class="text-box" v-html="detailInfo.hospDescription"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState,mapMutations} from 'vuex';
import WelfareBreadcrumb from "@/components/welfareMallComp/WelfareBreadcrumb";
import SearchCom from "@/components/welfareMallComp/SearchCom";
import {getAreaList, getServiceList,getDataType, getHospitalList,getReqHospitalDeail,getReqHospitalMealList,getReqHospSchedule,AddHealthCart } from "network/welfareMall_api.js";
export default {
  name: "OrganizationList",
  components: {
    WelfareBreadcrumb,
    SearchCom
  },
  data() {
    return {
      itemBreadcrumb: [
        {
          name: '福利商城',
          to: '/welfareHome'
        },
        {
          name: '员工体检',
        }
      ],
      bannerList:[],
      detailInfo:{},
      currentDay: new Date(),
      activeName: 'first',
      chooseList:{
        Sex: -1,
        MealType: '',
        HospId: ''
      },
      sexList:[
        {
          typeId: '1',
          typeName: '男性'
        },
        {
          typeId: '2',
          typeName: '女性'
        },
      ],
      mealId: '',
      taocanList:[],
      tableData: [],
      selectedDate:[],
      isShowAllAddressFlag: false,
      isTextEllipsis: false
    }
  },
  created(){
    let { hospId } = this.$route.query;
    this.chooseList.HospId = hospId;
    if(hospId){
      let data = {
        hospId: hospId
      }
      this.getdetailInfo(data);
      this.getTableList();
    }
    this.getType();
  },
  mounted(){
    
  },
  computed:{
    // 映射
    ...mapState(['useInfo'])
  },
  methods:{
    ...mapMutations(['saveCartNum']),
    showAllAdress(){
      this.isShowAllAddressFlag = true;
    },
    async getdetailInfo(data){
      let res = await getReqHospitalDeail(data);
      if(res.isSuccess){
        this.detailInfo = res.data;
        this.detailInfo.hospDescription = this.extractText(this.detailInfo.hospDescription);
        this.$nextTick(() => {
          let textElement = this.$refs.adressText;
          this.isTextEllipsis = textElement.scrollWidth > textElement.clientWidth;
        })
      }else{
        this.$message.error(res.msg)
      }
    },
    // 提取纯文本的方法
    extractText(data) {
      // 移除HTML标签
      let text = data.replace(/<[^>]*>/g, "");

      // 移除其他特殊字符（如转义字符、多余空格等）
      text = text.replace(/[\n\r\t]/g, " "); // 将换行符、制表符替换为空格
      text = text.replace(/\s+/g, " "); // 合并多余空格

      return text.trim(); // 去除首尾空格
    },
    // 点击查询 search
    searchBtn (val) {
      if(!val){
        return this.$message.info('请输入商品或礼包名称');
      }
      document.cookie = "vueRouter="+ '/welfareSearchResult';
      this.$router.push({
        name: 'WelfareSearchResult',
        params: {
          value: val
        }
      })
    },
    goTabs(){
      this.activeName = 'second';
    },
    handleClick(tab){
      if(tab.index == 0){
        this.activeName = 'first';
      }else{
        this.activeName = 'second';
      }
    },
    selectOption(type,value){
      this.chooseList[type] = value;
      this.getTableList();
    },
    // 获取类型
    async getType(){
      let res = await getDataType(1);
      if(res.isSuccess){
        this.taocanList = res.data;
      }else{
        this.$message.error(res.msg);
      }
    },
    async getTableList(){
      let data = { ...this.chooseList }
      let res = await getReqHospitalMealList(data);
      if(res.isSuccess){
        this.tableData = res.data.map(item => {
          let mealTypeArray;
          if (item.mealTypeDic && typeof item.mealTypeDic === 'object' && Object.keys(item.mealTypeDic).length > 0) {
            mealTypeArray = Object.entries(item.mealTypeDic).map(([id, name]) => ({
              id: id,
              name: name
            }));
          }else{
            mealTypeArray = [];
          }
          return {
            ...item,  
            mealTypeDic: mealTypeArray  
          };
        });
        this.mealId = this.tableData[0].mealId;
        this.getTimeList();
      }else{
        this.$message.error(res.msg);
      }
    },
    // 获取排期
    async getTimeList(){
      let data = {
        hospId: this.chooseList.HospId,
        sex: -1,
        mealId: this.mealId,
      }
      let res = await getReqHospSchedule(data);
      if(res.isSuccess){
        let date = new Date();
        date.setHours(0, 0, 0, 0);
        this.selectedDate = res.data.map(item =>({
          ...item,
          isSelectable: (item.usedNum - item.maxNum < 0 && new Date(item.date).getTime() >= date.getTime())
        }));
      }else{
        this.$message.error(res.msg)
      }
    },
    // 查看详情
    seeRowDetail(row){
      this.$router.push({
        name: "TijianMealDetails",
        query: { mealId: row.mealId },
      });
    },
    // 加入购物车
    async addCarBtn(mealItem){
      let myCartList = [];
      let cartObj = {};
      cartObj.mealID = mealItem.mealId;
      cartObj.mealName = mealItem.mealName;
      cartObj.mealPrice = mealItem.price;
      cartObj.mealUrl = mealItem.mealImage;
      cartObj.mealCount = 1;
      cartObj.festivalName = '员工体检';
      cartObj.isActive = true;// 初始化当前套餐显示为有效
      cartObj.amount = mealItem.price * 1;//金额
      cartObj.orderType = 4;//类型
      // 判断是否已在缓存中添加有购物车
      if(sessionStorage.getItem('myCartList') && !this.useInfo.privateUserID){
        myCartList = JSON.parse(sessionStorage.getItem('myCartList'));
        // 此时是未登录状态添加进缓存
        // 判断是否有该礼包  无 则push缓存 反之
        var boo = myCartList.some((item)=>{
          return item.mealID === cartObj.mealID;
        })
        if(!boo){
          myCartList.push(cartObj);
        }else{
          // 循环判断缓存中是否有改同样礼包
          myCartList.forEach((item)=>{
            // 如果有 数量相加
            if(item.mealID === cartObj.mealID){
              item.mealCount += cartObj.mealCount;
              item.amount = (item.mealCount * item.mealPrice);
            }
          })
        }
        sessionStorage.setItem('myCartList', JSON.stringify(myCartList));
        this.saveCartNum(myCartList.length);
        this.$message.success('加入购物车成功');
        // 判断是否是登录状态
      }else if(!this.useInfo.privateUserID){
        myCartList.push(cartObj);
        sessionStorage.setItem('myCartList', JSON.stringify(myCartList));
        this.saveCartNum(myCartList.length);
        this.$message.success('加入购物车成功');
        // 已登录状态
      }else if(this.useInfo.privateUserID){
        const {mealID, mealCount} = cartObj;
        // 加入购物车调登录的加入接口
        var res = await AddHealthCart([{
          mealID,
          mealCount
        }]);
        if(res.isSuccess){
          this.$message.success(res.msg);
          // 在vuex中调用获取当前登录用户的购物车数量
          this.$store.dispatch('getLoginCartNum');
        }else{
          this.$message.error(res.msg);
        }
      }
      
    }
  }
}
</script>
<style lang="less" scoped>
.organization_del_box{
  .w1200{
    width: 1200px;
    margin: 30px auto;
    .top-info-box{
      display: flex;
      justify-content: space-between;
      .left-box{
        display: flex;
        .carousel-box{
          width: 400px;
          height: 300px;
          margin-right: 20px;
          border-radius: 10px 10px 10px 10px;
          overflow: hidden;
        }
        .introduct-box{
          padding: 14px 0;
          box-sizing: border-box;
          width: 406px;
          h3{
            font-weight: bold;
            font-size: 18px;
            color: #333333;
            margin-bottom: 23px;
          }
          .introduct-item{
            display: flex;
            color: #333333;
            font-size: 14px;
            margin-bottom: 23px;
            .label-text{
              width: 70px;
            }
            .gray-text{
              color: #666666;
              flex: 1;
            }
            .one-line{
              width: 300px;
              white-space: nowrap;  
              overflow: hidden;     
              text-overflow: ellipsis;  
            }
            .show-all-adress{
              white-space: normal;
            }
            .bref-text{
              word-break: break-all;
              display: -webkit-box;        
              -webkit-box-orient: vertical; 
              -webkit-line-clamp: 2;       
              overflow: hidden;           
              text-overflow: ellipsis;   
            }
            .blue-text{
              color: #4C80FA;
              cursor: pointer;
            }
          }
          .df{
            display: flex;
            .label-text{
              width: 70px;
            }
            .right-text{
              flex: 1;
            }
          }
        }
      }
      .right-box{
        width: 300px;
        border: 1px solid #CCCCCC;
        padding: 13px !important;
        box-sizing: border-box;
        /deep/.el-calendar-table td{
          border: 0 !important;
        }
        /deep/.el-button--mini{
          padding: 7px !important;
        }
        .day-item{
          display: flex;
          flex-direction: column;
          align-items: center;
          .spot{
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50% ;
          }
          .green-spot{
            background-color: #25C98F !important;
          }
          .gray-spot{
            background-color: #CCCCCC !important;
          }
        }
        .el-calendar{
          /deep/.el-calendar__header{
            padding: 12px 0 !important;
            border: 0 !important;
            }
          /deep/.el-calendar-day{
            width: 40px;            
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          /deep/.el-calendar__body{
            padding: 12px 0 !important;
          }
        }
        .spot-box{
          display: flex;
          justify-content: end;
          margin-top: 20px;
          .spot-item{
            display: flex;
            align-items: center;
            margin-right: 20px;
            .spot{
              width: 8px;
              height: 8px;
              border-radius: 50%;
              margin-right: 4px;
            }
            .green-spot{
              background: #25C98F;
            }
            .gray-spot{
              background: #CCCCCC;
            }
          }
        }
      }
    }
    .tabs-box{
      .second-box{
        h3{
          padding: 16px 0;
          text-align: center;
          font-weight: bold;
          font-size: 18px;
          color: #333333;
        }
        .text-box{
          word-break: break-all;
          font-size: 14px;
          color: #333333;
          line-height: 26px;
        }
      }
      .tabs-contant-box{
        .choose_box{
          margin-top: 30px;
          .choose_item{
            display: flex;
            margin-bottom: 20px;
            .choose_text{
              display: inline-block;
              width: 70px;
              font-weight: 700;
              font-size: 14px;
              color: #333333;
              text-align: justify;
              text-align-last: justify;
            }
            .list_box{
              flex: 1;
              display: flex;
              flex-wrap: wrap;
              position: relative;
              .list_item_box{
                cursor: pointer;
                flex:0 0 106px;
                display: inline-block;
              }
              .list_item{
                max-width: 104px;
                display: inline-block;
                font-size: 14px;
                color: #333333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                &:nth-child(1){
                  margin-left: 0;
                }
              }
              .choose_active{
                padding:3px 10px;
                font-size: 14px;
                color: #4C80FA;
                border:1px solid #4C80FA;
                border-radius: 5px;
              }
              .list_more{
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;          
                font-size: 14px;
                color: #333333;
              }
            }
          }
        }
        // 表格
        .table-box{
          .meal-name{
            font-weight: bold;
            font-size: 14px;
            color: #333333;
            margin-bottom: 10px;
          }
          .tag-box{
            display: flex;
            // justify-content: center;
            .tag-item{
              padding: 0 10px;
              line-height: 26px;
              color: #4C80FA;
              border: 1px solid #4C80FA;
              border-radius: 14px;
              margin-right: 10px;
            }
          }
          .price-box{
            display: flex;
            justify-content: center;
            font-weight: bold;
            font-size: 22px;
            color: #F64F42;
            .fz16{
              font-size: 16px;
            }
          }
          .btn-box{
            display: flex;
            flex-direction: column;
            align-items: center;
            .btn-item{
              display: flex;
              justify-content: center;
              align-items: center;
              width: 90px;
              height: 30px;
              border-radius: 16px;
              cursor: pointer;
            }
            .see-btn{
              border: 1px solid #4C80FA;
              color: #4C80FA;
              margin-bottom: 10px;
            }
            .add-btn{
              margin-bottom: 8px;
              border: 1px solid #4C80FA;
              background: #4C80FA;
              color: #fff;
            }
          }
        }
      }
    }

  }
}
</style>